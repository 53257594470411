<template>
  <v-row class="overflow-hidden">
    <v-col cols="12" md="6">
      <div class="feature-bento-box-content max-w-full">
        <div>
          <strong class="text-white pre-headline clickable-hover-effect">{{
            preHeadline
          }}</strong>
          <h2
            class="feature-bento-box-title"
            :class="{ 'no-gradient': noGradient }"
          >
            {{ parse(headline) }}
          </h2>
        </div>
        <div class="feature-bento-box-summary">
          <div v-html="teaser"></div>
        </div>

        <div v-if="badges.length" class="badges">
          <Badge
            v-for="(badge, i) in badges"
            :key="i"
            :icon="badge.image?.filename"
            :title="badge.headline"
          />
        </div>
        <div v-if="false && badges.length" class="badges">
          <FeatureGridSmall single-item>
            <ListItemWithIcon2
              v-for="(item, i) in outcomesForFeatureGrid({ outcomes: badges })"
              :key="i"
              :blok="item"
            >
            </ListItemWithIcon2>
          </FeatureGridSmall>
          <!-- <Badge
          v-for="(badge, i) in product.content.outcomes"
          :key="i"
          :icon="badge.image?.filename"
          :title="badge.headline"
        /> -->
        </div>
        <MyLink v-bind="link" :button="false" class="my-4" />
      </div>
    </v-col>
    <v-col cols="12" md="6" class="relative pb-0 h-100">
      <NuxtImg
        v-if="image?.filename"
        sizes="sm:200px md:300px lg:500px"
        :src="image.filename"
        :alt="image.alt"
        class="teaser-image mx-auto"
        :class="[
          {
            'rounded rounded-lg h-100': !fitImage,
            'clickable-hover-effect hover-effect-soft cursor-pointer': link,
          },
        ]"
      />
    </v-col>
  </v-row>
</template>
<script setup>
const { parse } = useUtils();
const { outcomesForFeatureGrid } = await useProducts();

defineProps({
  noGradient: Boolean,
  preHeadline: String,
  headline: String,
  teaser: String,
  link: Object,
  image: Object,
  imagePosition: {
    type: String,
    default: "center center",
  },
  imageFit: {
    type: String,
    default: "cover",
  },
  fitImage: {
    type: Boolean,
    default: false,
  },
  badges: {
    type: Array,
    default: () => [],
  },
});
</script>

<style scoped>
.max-w-full {
  max-width: 100% !important;
}
.pre-headline {
  font-weight: 100;
  font-size: 12px;
}
.teaser-image {
  bottom: 0;
  right: 0;
  height: 100%;
  object-fit: v-bind(imageFit);
  object-position: v-bind(imagePosition);

  @media screen and (max-width: 991px) {
    width: 100%;
    max-height: 50vh;
  }
}
</style>
